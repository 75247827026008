var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "기본정보" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "저장",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveInfo,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "허가증상 일련번호",
                              name: "envAirMstOutletSems",
                            },
                            model: {
                              value: _vm.data.envAirMstOutletSems,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envAirMstOutletSems", $$v)
                              },
                              expression: "data.envAirMstOutletSems",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "배출구 일련번호",
                              name: "envAirMstOutletName",
                            },
                            model: {
                              value: _vm.data.envAirMstOutletName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "envAirMstOutletName", $$v)
                              },
                              expression: "data.envAirMstOutletName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "위치",
                              name: "location",
                            },
                            model: {
                              value: _vm.data.location,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "location", $$v)
                              },
                              expression: "data.location",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "㎥/분",
                              type: "number",
                              required: true,
                              editable: _vm.editable,
                              label: "처리용량",
                              name: "handlingCapacity",
                            },
                            model: {
                              value: _vm.data.handlingCapacity,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "handlingCapacity", $$v)
                              },
                              expression: "data.handlingCapacity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "㎥/분",
                              type: "number",
                              editable: _vm.editable,
                              label: "설계(허가증)용량",
                              name: "permitCapacity",
                            },
                            model: {
                              value: _vm.data.permitCapacity,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "permitCapacity", $$v)
                              },
                              expression: "data.permitCapacity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.editable,
                              label: "구입일",
                              name: "buyDt",
                            },
                            model: {
                              value: _vm.data.buyDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "buyDt", $$v)
                              },
                              expression: "data.buyDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "메이커",
                              name: "maker",
                            },
                            model: {
                              value: _vm.data.maker,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "maker", $$v)
                              },
                              expression: "data.maker",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-dept", {
                            attrs: {
                              type: "edit",
                              label: "관리부서",
                              name: "deptCd",
                              isFirstValue: false,
                            },
                            model: {
                              value: _vm.data.deptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "deptCd", $$v)
                              },
                              expression: "data.deptCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-3" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.editable,
                              isFlag: true,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.data.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "useFlag", $$v)
                              },
                              expression: "data.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.param.envAirMstOutletId,
                expression: "param.envAirMstOutletId",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
          },
          [
            _c(
              "c-table",
              {
                ref: "grid",
                attrs: {
                  title: "자가측정 검사항목",
                  tableId: "grid",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  isFullScreen: false,
                  filtering: false,
                  isExcelDown: false,
                  gridHeight: "400px",
                  selection: "multiple",
                  rowKey: "envAirPlantInspectItemId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.param.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "저장", icon: "save" },
                              on: { btnClicked: _vm.saverow1 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.param.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addrow1 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.param.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "삭제", icon: "remove" },
                              on: { btnClicked: _vm.removeRow1 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.param.envAirMstOutletId,
                expression: "param.envAirMstOutletId",
              },
            ],
            staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
          },
          [
            _c(
              "c-table",
              {
                ref: "grid2",
                attrs: {
                  title: "연결 배출시설",
                  tableId: "grid2",
                  columns: _vm.grid2.columns,
                  data: _vm.grid2.data,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  isFullScreen: false,
                  filtering: false,
                  isExcelDown: false,
                  gridHeight: "400px",
                  selection: "multiple",
                  editable: _vm.editable,
                  rowKey: "envAirMstDischargeId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && _vm.param.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addrow2 },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.param.envAirMstOutletId
                          ? _c("c-btn", {
                              attrs: { label: "삭제", icon: "remove" },
                              on: { btnClicked: _vm.removeRow2 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }