<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
                <div class="col-3">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="허가증상 일련번호"
                    name="envAirMstOutletSems"
                    v-model="data.envAirMstOutletSems">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="배출구 일련번호"
                    name="envAirMstOutletName"
                    v-model="data.envAirMstOutletName">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-text
                    :editable="editable"
                    label="위치"
                    name="location"
                    v-model="data.location">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text
                    suffix="㎥/분"
                    type="number"
                    :required="true"
                    :editable="editable"
                    label="처리용량"
                    name="handlingCapacity"
                    v-model="data.handlingCapacity">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text
                    suffix="㎥/분"
                    type="number"
                    :editable="editable"
                    label="설계(허가증)용량"
                    name="permitCapacity"
                    v-model="data.permitCapacity">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
                </div>
                <div class="col-3">
                  <c-datepicker
                    :editable="editable"
                    label="구입일"
                    name="buyDt"
                    v-model="data.buyDt"
                  />
                </div>
                <div class="col-3">
                  <c-text
                    :editable="editable"
                    label="메이커"
                    name="maker"
                    v-model="data.maker">
                  </c-text>
                </div>
                <div class="col-3">
                  <c-dept type="edit" label="관리부서" name="deptCd" v-model="data.deptCd" :isFirstValue="false" />
                </div>
                <div class="col-3">
                  <c-checkbox
                    :editable="editable"
                    :isFlag="true"
                    label="사용여부"
                    name="useFlag"
                    v-model="data.useFlag"
                  />
                </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" v-show="param.envAirMstOutletId">
        <c-table
          ref="grid"
          title="자가측정 검사항목"
          tableId="grid"
          :columns="grid.columns"
          :data="grid.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="400px"
          selection="multiple"
          rowKey="envAirPlantInspectItemId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="저장" v-if="editable && param.envAirMstOutletId" icon="save" @btnClicked="saverow1" />
              <c-btn label="추가" v-if="editable && param.envAirMstOutletId" icon="add" @btnClicked="addrow1" />
              <c-btn label="삭제" v-if="editable && param.envAirMstOutletId" icon="remove" @btnClicked="removeRow1" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-show="param.envAirMstOutletId">
        <c-table
          ref="grid2"
          title="연결 배출시설"
          tableId="grid2"
          :columns="grid2.columns"
          :data="grid2.data"
          :usePaging="false"
          :hideBottom="true"
          :columnSetting="false"
          :isFullScreen="false"
          :filtering="false"
          :isExcelDown="false"
          gridHeight="400px"
          selection="multiple"
          :editable="editable"
          rowKey="envAirMstDischargeId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" v-if="editable && param.envAirMstOutletId" icon="add" @btnClicked="addrow2" />
              <c-btn label="삭제" v-if="editable && param.envAirMstOutletId" icon="remove" @btnClicked="removeRow2" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'water-master03-detail',
  props: {
    param: {
      type: Object,
      default(){
        return {
          envAirMstOutletId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
        envAirMstOutletId: '',  // 대기배출구 시스템 일련번호
        envAirMstOutletName: '',  // 배출구일련번호
        envAirMstOutletSems: '', // 허가증상 일련번호
        location: '',  // 위치
        handlingCapacity: '',  // 방지시설처리용량
        permitCapacity: '',  // 방지시설처리용량
        buyDt: '',  // 구입일자
        maker: '',  // 메이커
        deptCd: '',  // 관리부서 코드
        useFlag: 'Y',  // 사용여부
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '50%',
        top: '',
        param: {},
        closeCallback: null,
      },
      itemListUrl: '',
      itemInsertUrl: '',
      itemDeleteUrl: '',
      disListUrl: '',
      disInsertUrl: '',
      disDeleteUrl: '',
      saveUrl: transactionConfig.env.air.mst.outlet.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      grid: {
        columns: [
          {
            name: 'airInspectTypeName',
            field: 'airInspectTypeName',
            label: '검사구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'envAirMstInspectItemName',
            field: 'envAirMstInspectItemName',
            label: '검사항목',
            align: 'center',
            sortable: true,
          },
          {
            name: 'airInspectUnitName',
            field: 'airInspectUnitName',
            label: '단위',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'legalStandard',
            field: 'legalStandard',
            label: '법적기준',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'innerStandard',
            field: 'innerStandard',
            label: '내부기준',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'envAirMstDischargeNo',
            field: 'envAirMstDischargeNo',
            label: '관리번호',
            align: 'center',
            sortable: false,
          },
          {
            name: 'envAirMstDischargeName',
            field: 'envAirMstDischargeName',
            label: '배출시설명',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.mst.outlet.get.url;
      this.saveUrl = transactionConfig.env.air.mst.outlet.insert.url;
      this.itemListUrl = selectConfig.env.air.mst.outlet.inspect.url;
      this.itemInsertUrl = transactionConfig.env.air.mst.outlet.inspect.save.url;
      this.itemDeleteUrl = transactionConfig.env.air.mst.outlet.inspect.delete.url;
      this.disListUrl = selectConfig.env.air.mst.outlet.discharge.url;
      this.disInsertUrl = transactionConfig.env.air.mst.outlet.discharge.save.url;
      this.disDeleteUrl = transactionConfig.env.air.mst.outlet.discharge.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.envAirMstOutletId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirMstOutletId: this.param.envAirMstOutletId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.getItemList();
          this.getDisList();
        },);
      }
    },
    getItemList() {
      if (this.param.envAirMstOutletId) {
        this.$http.url = this.itemListUrl;
        this.$http.param = {envAirMstOutletId: this.param.envAirMstOutletId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getDisList() {
      if (this.param.envAirMstOutletId) {
        this.$http.url = this.disListUrl;
        this.$http.param = {envAirMstOutletId: this.param.envAirMstOutletId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    saveInfo() {
      if (this.param.envAirMstOutletId) {
        this.saveUrl = transactionConfig.env.air.mst.outlet.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.env.air.mst.outlet.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.envAirMstOutletId = result.data.envAirMstOutletId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertOutletInfo', result.data.envAirMstOutletId);
      }
      this.getDetail();
    },
    saverow1() {
      this.$http.url = this.itemInsertUrl;
      this.$http.type = 'POST';
      this.$http.param = this.grid.data;
      this.$http.request(() => {
        this.getItemList();
      });
    },
    addrow1() {
      this.popupOptions.title = '대기 검사항목';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./inspectItemPlantPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup1;
    },
    closePopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { envAirMstInspectItemId: item.envAirMstInspectItemId }) === -1) {
            saveData.push({
              envAirPlantInspectItemId: item.envAirPlantInspectItemId,  // 사업장별 대기 검사항목 일련번호
              envAirMstOutletId: this.param.envAirMstOutletId,  // 대기배출구(방지시설) 일련번호
              envAirMstInspectItemId: item.envAirMstInspectItemId,
              airInspectTypeName: item.airInspectTypeName,
              envAirMstInspectItemName: item.envAirMstInspectItemName,
              airInspectUnitName: item.airInspectUnitName,
              legalStandard: item.legalStandard,
              innerStandard: item.innerStandard,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
          }
        });
        this.$http.url = this.itemInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getItemList();
        });
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.itemDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.data.plantCd
      };
      this.popupOptions.target = () => import(`${'./airMasterDischargePop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup2;
    },
    closePopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid2.data, { envAirMstDischargeId: item.envAirMstDischargeId }) === -1) {
            saveData.push({
              plantCd: item.plantCd,  // 사업장별 대기 검사항목 일련번호
              envAirMstOutletId: this.param.envAirMstOutletId,
              envAirMstDischargeId: item.envAirMstDischargeId,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
            });
          }
        });
        this.$http.url = this.disInsertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getDisList();
        });
      }
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.disDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow2() {
      let checkItem = ['envAirMstOutletDischargeName']
      let isConti = true;
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [배출시설명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid2.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.disInsertUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDisList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    }
  }
};
</script>